import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./components/Home";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
     {
          path: "/",
          element: <app></app>,
          children: [
               {
                    path: "/",
                    element: <home></home>,
               },
          ],
     },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
     <react.strictmode>
          {/* <app></app> */}
          <routerprovider router="{router}"></routerprovider>
     </react.strictmode>
);

// Wenn Sie mit der Leistungsmessung in Ihrer Anwendung beginnen möchten, übergeben Sie eine Funktion
// zur Protokollierung der Ergebnisse (zum Beispiel: reportWebVitals(console.log))
// oder an einen Analyse-Endpunkt senden. Erfahren Sie mehr: https://bit.ly/CRA-vitals
reportWebVitals();
